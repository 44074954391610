
.customSlider {
  /* max width of slider */
  max-width: 387px;
  /* Optional: Only to center slider in the page */
  margin: auto;
  margin-top: 20px;
  margin-left: 7px;
  margin-right: 7px;
}

.customSlider-inverted {
  /* max width of slider */
  max-width: 387px;
  /* Optional: Only to center slider in the page */
  margin: auto;
  margin-top: 20px;
  margin-left: 7px;
  margin-right: 7px;
}

.customSlider-track {
  /* Top value to align track to the center of your thumb */
  top: 3px;
  /* thickness of the track */
  height: 4px;
  pointer-events: none;
}

.customSlider .customSlider-track.customSlider-track-0 {
  /* color of the track before the first thumb */
  background: rgb(13, 160, 0);
  pointer-events: none;
}

.customSlider .customSlider-track.customSlider-track-1 {
  /* color of the track between thumbs */
  background: rgb(255, 174, 0);
  pointer-events: none;
}


.customSlider .customSlider-track.customSlider-track-2 {
  /* color of the track after the second thumb */
  background: rgb(200, 4, 4);
  pointer-events: none;
}

.customSlider-inverted .customSlider-track.customSlider-track-2 {
  /* color of the track before the first thumb */
  background: rgb(13, 160, 0);
  pointer-events: none;
}

.customSlider-inverted .customSlider-track.customSlider-track-1 {
  /* color of the track between thumbs */
  background: rgb(255, 174, 0);
  pointer-events: none;
}


.customSlider-inverted .customSlider-track.customSlider-track-0 {
  /* color of the track after the second thumb */
  background: rgb(200, 4, 4);
  pointer-events: none;
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: rgb(0, 0, 0);
  /* shape of the thumb: circle */
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: relative;

  /* remove default outline when selected */
  /* outline: none; */
  
}

.rag-tooltip {
  font-size: 12px;

  visibility: hidden;
  width: 20px;
  background-color: rgb(0, 0, 0, 0.75);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;

  bottom: 150%;
  left: 50%;
  margin-left: -10px;

}

.customSlider-thumb .rag-tooltip::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(0, 0, 0, 0.75) transparent transparent transparent;
}

.customSlider-thumb:hover .rag-tooltip{
  visibility: visible;
}

.rag-container {
  margin-top: -20px;
    
}

.rag-display {
  max-width: 430px;
  padding-left: 19px;
  padding-right: 20px;
  height: 30px;
  align-content: center;
  justify-content: center;
}

.rag-display-green {
  background: rgb(13, 160, 0, 50%);
  padding: 0px;
  padding-bottom: 5px;
  margin: 2px;
  height: 25px;
  text-align: center;
}

.rag-display-amber {
  background: rgb(255, 174, 0, 50%);
  padding: 0px;
  margin: 2px;
  height: 25px;
  text-align: center;
}

.rag-display-red {
  background: rgb(200, 4, 4, 50%);
  padding: 0px;
  margin: 2px;
  height: 25px;
  text-align: center;

}

.rag-border {
  position: relative;
  border: solid 2px rgb(0, 55, 73, 50%);
  height: 60px;
  max-width: 400px;
  top: 35px;
  border-radius: 4%;
}

.rag-label {
  position: relative;
  top: 35px;
  font-size: small;
  color: rgb(0, 55, 73);
}
