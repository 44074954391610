
.img-preview {
  height: auto;
  width: 100%;
}

.mantine-Carousel-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  max-height: 600px;
}