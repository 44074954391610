:root {
  --crblue: #003749;
  --crred: #ff6a70;
  --crturk: #48cfe5;
  --crbeige: #f2eeeb;
  --crblack: #1d1d1d;
  --crwhite: #ffffff; 
}

.footer {
  background-color: var(--crblue);
  position: bottom; 
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--crwhite);
  border-top: solid var(--crturk);
  padding-top: 20px;
  font-size: xx-small;
  letter-spacing: 1px;
}

.footer-logo {
  max-width: 20%;
  height: auto;
}

ul {
  list-style-type: none;
}

.header {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: var(--crblue);
  padding: 20px;
}

.header-home {
  background-color: var(--crblue);
  padding: 20px;
}

.header h1 {
  color: var(--crwhite);
  text-align: center;
  margin-bottom: 0;
  font-size: 40px;
}

.header p {
  text-align: center;
}

.header .logo {
  text-align: left;
}

.header-home .logo {
  text-align: center;
}

.header .logo img {
  cursor: pointer;
  width: 100px;
}

#logo-img {
  cursor: pointer;
  width: 50px;
}

.header-home .logo img {
  cursor: pointer;
  width: 100px;
}

.menu {
  background-color: var(--crblue);
  text-align: center;
  padding: 15px 0;
  border-top: solid var(--crturk);
}

.menu .menuItem {
  display: inline-block;
  margin: 0 30px;
}

.menu .menuItem a {
  color: var(--crwhite);
  text-decoration: none;
}

.page {
  background: var(--crbeige);
  /* margin-left: 120px;
  margin-right: 120px; */
  padding-top: 60px;
  min-height: 100vh;
  ;
}

/* .page h2 {
  text-align: center;
} */

/* .page p {
  text-align: center;
} */

.inputs .input {
  text-align: center;
}

.inputs .input input {
  height: 30px;
  width: 300px;
  margin: 5px 0;
}

.inputs .button {
  text-align: center;
}

.inputs .button button {
  background-color: var(--crblue);
  opacity: 0.75;
  border: none;
  padding: 15px 30px;
  color: var(--crwhite);
  width: 308px;
  margin: 10px 0;
  cursor: pointer;
}

.error {
  background-color: var(--crred);
  width: 280px;
  margin: 5px 0;
  margin: 0 auto;
  padding: 15px;
  color: var(--crwhite);
}

.container-settings{
  background-color: var(--crwhite);
  max-width: 80%;
  /* box-shadow: 2px 2px 10px; */
  margin-bottom: 40px;
  padding: 40px;
}
