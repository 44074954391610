.horizontal-line-with-markers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-container {
  padding: 16px;
  margin-bottom: 16px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #000;
}

.top-marker {
  position: absolute;
  width: 1px;
  height: 16px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.marker {
  position: absolute;
  width: 1px;
  height: 16px;
  background-color: #000;
  bottom: -8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-marker-lable {
  font-size: 10px;
  }

.month-label {
  font-size: 10px;
  margin-top: 16px;
}

.mantine-Accordion-panel {
  word-break: normal;

}

