body {
  margin: 0;
  background: var(--crwhite);
  font-family: 'Barlow';
  font-weight: 50;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Barlow', sans-serif;
}